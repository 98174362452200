import type { OrderType } from '~/types/order'
import type { Merchant } from './types'
import type { Merchant as AppMerchant } from '~/types/merchant'
import type {
  DesktopThemeSettings,
  MerchantThemeSettings,
  ThemeSettings,
} from '~/types/theme'
import type { FooterType, HeaderType, ProductCardType } from './types/theme'

export function getCoordinates(merchant: Merchant): [number, number] | null {
  if (!merchant.coordinates) return null

  const { latitude, lontitude } = merchant.coordinates

  if (latitude && lontitude) {
    return [Number(lontitude), Number(latitude)]
  }

  return null
}

export function makeMerchant(merchant: Merchant): AppMerchant {
  const orderTypes: OrderType[] = [
    { label: 'Доставка', code: 'delivery' },
    { label: 'Самовывоз', code: 'pickup' },
    { label: 'В зале', code: 'dinein' },
  ]

  return {
    ...merchant,
    coordinates: getCoordinates(merchant),
    orderTypes: orderTypes.filter((type) =>
      merchant.orderTypes.includes(type.code),
    ),
    categories: merchant.categories.map((category) => ({
      ...category,
      image: category.image?.includes('no-photo') ? null : category.image,
    })),
    deliveryZones:
      merchant.deliveryZones?.map((zone) => ({
        ...zone,
        payments: [],
      })) || [],
    bonusSystemEnabled: !merchant.bonusesSystemDisabled,
    theme: makeMerchantThemeSettings(merchant),
    whiteLabel: !merchant.whiteLabelDisabled,
  }
}

export function makeMerchantThemeSettings(
  merchant: Merchant,
): MerchantThemeSettings {
  if (merchant.theme.fontFamily === 'Gotham Pro') {
    merchant.theme.fontFamily = 'Nunito'
  }

  const settings = merchant.themeSettings

  return {
    desktop: {
      primaryColor:
        settings?.style?.desktop?.color ||
        merchant.theme.primaryColor ||
        '#333',
      fontFamily:
        settings?.style?.desktop?.font || merchant.theme.fontFamily || 'Nunito',
      borderRadiusRatio:
        settings?.style?.desktop?.borderRadius !== undefined
          ? Number(settings.style.desktop.borderRadius) / 100
          : 1,
      dark: settings?.style?.desktop?.colorScheme === 'dark',
      productCard: {
        type: getProductCardType(settings?.catalog?.desktop?.dishCardDisplay),
      },
      header: {
        type: getHeaderType(settings?.homePage?.desktop?.headerDisplay),
      },
      footer: {
        type: getFooterType(settings?.homePage?.desktop?.footerDisplay),
      },
      background: {
        fixed: settings?.style?.desktop?.backgroundOption?.type === 'static',
        image:
          settings?.style?.desktop?.backgroundOption?.type === 'standard'
            ? null
            : settings?.style?.desktop?.backgroundOption?.imageUrl,
      },
      homePage: {
        products: {
          show: settings?.homePage?.desktop?.menuDisplay !== 'tile',
        },
        categories: {
          show: settings?.homePage?.desktop?.menuDisplay !== 'carousel',
        },
      },
    },

    mobile: {
      primaryColor:
        settings?.style?.mobile?.color || merchant.theme.primaryColor || '#333',
      fontFamily:
        settings?.style?.mobile?.font || merchant.theme.fontFamily || 'Nunito',
      borderRadiusRatio:
        settings?.style?.mobile?.borderRadius !== undefined
          ? Number(settings.style.mobile.borderRadius) / 100
          : 1,
      dark: settings?.style?.mobile?.colorScheme === 'dark',
      productCard: {
        type: getProductCardType(settings?.catalog?.mobile?.dishCardDisplay),
      },
      homePage: {
        products: {
          show: settings?.homePage?.mobile?.menuDisplay !== 'tile',
        },
        categories: {
          show: settings?.homePage?.mobile?.menuDisplay !== 'carousel',
        },
      },
      background: {
        fixed: settings?.style?.mobile?.backgroundOption?.type === 'static',
        image:
          settings?.style?.mobile?.backgroundOption?.type === 'standard'
            ? null
            : settings?.style?.mobile?.backgroundOption?.imageUrl,
      },
    },
  }
}

function getProductCardType(value?: ProductCardType) {
  if (!value) return 'guestChoice'

  const productCardTypes: Record<
    ProductCardType,
    ThemeSettings['productCard']['type']
  > = {
    short: 'default',
    detailed: 'detailed',
    guestChoice: 'guestChoice',
  }

  return productCardTypes[value]
}

function getHeaderType(value?: HeaderType) {
  if (!value) return 'minimalistic'

  const headerTypes: Record<
    HeaderType,
    DesktopThemeSettings['header']['type']
  > = {
    minimal: 'minimalistic',
    left: 'logoLeft',
    center: 'logoCenter',
  }

  return headerTypes[value]
}

function getFooterType(value?: FooterType) {
  if (!value) return 'minimalistic'

  const headerTypes: Record<
    HeaderType,
    DesktopThemeSettings['header']['type']
  > = {
    minimal: 'minimalistic',
    left: 'logoLeft',
    center: 'logoCenter',
  }

  return headerTypes[value]
}
